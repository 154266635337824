<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>



<style lang="scss">
@import "~@/assets/css/_variables.css";
@import "~@/assets/css/main.css";
@import "~@/assets/css/rwd.css";

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
