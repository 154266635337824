import { post } from "@/utils/request"
import { responseSuccessful } from "@/utils/response"

/**
 * 取得隱私權政策、公司簡介、退換貨說明之類的網站內容
 * @param {String} key
 * @returns
 */
export function getWebContent(key = '') {
  return post('webcontent/getcopywriting').then(res => {
    if (responseSuccessful(res.data.code)) {
      if (key) {
        return res.data.data[key]
      } else {
        return res.data.data
      }
    }
  });
}

/**
 * 取得網站資料
 * @returns 
 */
export function webConfig() {
  return post('web/getwebdata').then(res => {
    if (responseSuccessful(res.data.code)) {
      return res.data.data
    }
  });
}

/**
 * 取得圖形驗證碼
 * @returns 
 */
export function imageVerifyCode() {
  return post('imagecode/getimgcode').then(res => {
    if (responseSuccessful(res.data.code)) {
      return res.data.data
    }
  });
}

/**
 * 取得聯絡我們問題清單
 * @returns 
 */
export function contactQuestionType() {
  return post('contactus/getquestiontype').then(res => {
    if (responseSuccessful(res.data.code)) {
      return res.data.data
    }
  });
}

/**
 * 聯絡我們
 * @param {{name: String, gender?:String, phone?:String, mail?:String, question_type:String, remark:String, key:String, code:String}} data
 * @returns
 */
export function contactUs(data) {
  return post('contactus/create', data).then(res => {
    return res.data
  });
} 
