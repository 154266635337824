import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth.js'
import { webConfig } from "@/apis/content.js"

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('@/views/maintenance.vue'),
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('@/Layout.vue'),
    meta: {
      title: '維濟生技',
      hidden: true,
      hiddenTab: true
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home/index.vue'),
      },
      {
        path: '/forget-password',
        name: 'ForgetPassword',
        component: () => import('@/views/Login/forget_password.vue'), // 忘記密碼
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login/login.vue'), // 登入
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Login/register.vue'), // 註冊
      },
      {
        path: '/third_register',
        name: 'thirdRegister',
        component: () => import('@/views/Login/third_register.vue'),
        props: true
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('@/views/About/detail.vue'), // 關於我們
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('@/views/News/list.vue'), // 最新消息
      },
      {
        path: '/news/detail/*',
        name: 'NewsDetail',
        component: () => import('@/views/News/detail.vue'), // 最新消息 - 文章
      },
      {
        path: '/witnesses_share',
        name: 'WitnessesShare',
        component: () => import('@/views/WitnessesShare/list.vue'), // 健康教室 - 見證者分享
      },
      {
        path: '/witnesses_share/detail/*',
        name: 'WitnessesShareDetail',
        component: () => import('@/views/WitnessesShare/detail.vue'), // 康教室 - 見證者分享 - 文章
      },
      {
        path: '/health',
        name: 'Health',
        component: () => import('@/views/Health/list.vue'), // 健康教室 - 健康小撇步
      },
      {
        path: '/health/detail/*',
        name: 'HealthDetail',
        component: () => import('@/views/Health/detail.vue'), // 健康教室 - 健康小撇步 - 文章
      },
      {
        path: '/cart-list',
        name: 'CartList',
        component: () => import('@/views/Cart/list.vue'),
      },
      {
        path: '/cart-info',
        name: 'CartInfo',
        component: () => import('@/views/Cart/info.vue'),
      },
      {
        path: '/cart-confirmed',
        name: 'CartConfirmed',
        component: () => import('@/views/Cart/confirmed.vue'),
      },
      {
        path: '/product-detail/:id',
        name: 'ProductDetail',
        component: () => import('@/views/Product/detail.vue'),
        props: true,
        meta: {
          is_shopping: true
        }
      },
      {
        path: '/healthy_food',
        name: 'HealthyFood',
        component: () => import('@/views/Product/list.vue'),
      },
      {
        path: '/general_food',
        name: 'GeneralFood',
        component: () => import('@/views/Product/list.vue'),
      },
      {
        path: '/payed-callback',
        name: 'PayedCallback',
        component: () => import('@/views/Product/list.vue'),
      },
      {
        path: '/search',
        name: 'SearchList',
        component: () => import('@/views/Product/search.vue'),
      },
      {
        path: '/content/privacy',
        name: 'Privacy',
        component: () => import('@/views/Content/privacy.vue'),
      },
      {
        path: '/content/company',
        name: 'Company',
        component: () => import('@/views/Content/company.vue'),
      },
      {
        path: '/content/return_and_exchange',
        name: 'ReturnAndExchange',
        component: () => import('@/views/Content/return_and_exchange.vue'),
      },
      {
        path: '/content/terms_and_conditions',
        name: 'TermsAndConditions',
        component: () => import('@/views/Content/terms_and_conditions.vue'),
      },
      {
        path: '/content/faq',
        name: 'Faq',
        component: () => import('@/views/Content/faq.vue'),
      },
      {
        path: '/content/antifraud',
        name: 'Antifraud',
        component: () => import('@/views/Content/antifraud.vue'),
      },
      {
        path: '/member/edit',
        name: 'MemberEdit',
        component: () => import('@/views/Member/edit.vue'),
      },
      {
        path: '/member/order',
        name: 'MemberOrder',
        component: () => import('@/views/Member/order.vue'),
      },
      {
        path: '/member/order/order-detail/:id',
        name: 'MemberOrderDetail',
        component: () => import('@/views/Member/order-detail.vue'),
        props: true
      },
      {
        path: '/member/favorite',
        name: 'MemberFavorite',
        component: () => import('@/views/Member/favorite.vue'),
      },
      {
        path: '/member/coupon',
        name: 'MemberCoupon',
        component: () => import('@/views/Member/coupon.vue'),
      },
      {
        path: '/member/points',
        name: 'MemberPoint',
        component: () => import('@/views/Member/point.vue'),
      },
      {
        path: '/member/bonus',
        name: 'MemberBonus',
        component: () => import('@/views/Member/bonus.vue'),
      },
    ]
  },
  {
    path: '*', // 404
    redirect: '/'
  },
]

// 登入前白名單
const baseWhiteList = [
  '/',
  '/maintenance',
  '/login',
  '/register',
  '/third_register',
  '/forget-password',
  '/about',
  '/news',
  '/news/detail/.*',
  '/health',
  '/health/detail/.*',
  '/product-detail',
  '/product-list',
  '/article/.*',
  '/【search',
];

// 登入後禁止訪問
const leaveWhenGotToken = [
  '/login',
  '/register',
  '/forget-password',
];

const afterLogin = [
  '/member/.*',
  '/cart-list',
  '/cart-info',
  '/cart-confirmed',
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
  routes: baseRoutes,
});

// 全局路由守卫
router.beforeEach(async (to, from, next) => {
  let token = getToken();
  const config = await webConfig();
  localStorage.setItem('webConfig', JSON.stringify(config));
  const { enable } = config
  if (enable === 'Y') {
    if (to.path === '/maintenance') {
      next({ path: '/' });
    } else if (token && leaveWhenGotToken.includes(to.path)) {
      next({ path: '/' });
    } else if (!token && afterLogin.some(path => to.path.match(new RegExp('^' + path.replace('*', '.*') + '$')))) {
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else if (baseWhiteList.some((path) => to.path.match(new RegExp('^' + path.replace('*', '.*') + '$')))) {
      next();
    } else {
      next();
    }
  } else {
    if (to.path === '/') {
      next({ path: '/maintenance' });
    } else {
      next();
    }
  }

});

export default router;
