import axios from 'axios';
import { getToken } from './auth';
// import store from '@/store';
// import { getToken } from '@/utils/auth';
// import { setParams } from '@/utils/setParams';

// https://api.weijibiotech.com.tw/api/admin/  線上 api url
// http://weiji.api.sent.tw/api/admin/ local 開發用 api url

// 創建 axios 實例
const service = axios.create({
  // 取 env 裡的 api domain
  baseURL: `${ process.env.VUE_APP_API_DOMAIN || 'https://api.weijibiotech.com.tw' }/api/client`,
  timeout: 120000, // 请求超时时间
});

export default service;

export function post(url, params) {
  if (params === undefined) {
    params = {}
  } else {
    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || params[key] === null) {
        delete params[key];
      }
    });
  }

  return service({
    method: 'post',
    url,
    data: { token: getToken(), ...params }
  }).then(res => {
    if (res.data.code && res.data?.message === 'token錯誤') {
      localStorage.removeItem('token')
      window.location.href = '/login'
    }
    return res
  }).then(res => {
    return res
  })
}
