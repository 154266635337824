import Vue from 'vue'
import App from './App.vue'
import router from './router/route.js'
import VueScrollTo from 'vue-scrollto';
import 'bootstrap/dist/js/bootstrap.js';
Vue.config.productionTip = false

Vue.use(VueScrollTo, {
  duration: 500,
  easing: "ease",
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

