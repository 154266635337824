export const getToken = () => {
  let token = localStorage.getItem('token');
  if (!token) {
    return null
  }
  try {
    token = JSON.parse(token);
    if (new Date().getTime() > token.time) {
      localStorage.removeItem('token');
      return null
    } else {
      return token.token
    }
  } catch (error) {
    localStorage.removeItem('token');
    return null
  }
};

/**
 * 
 * @param {String} token
 * @returns 
 */
export const setToken = (token) => {
  return localStorage.setItem('token',
    JSON.stringify({ token: token, time: new Date().getTime() + (18000 * 1000) }))
}
